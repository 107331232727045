import {
  elevador,
  bar,
  bell,
  child,
  cine,
  fire,
  fuego,
  game,
  garaje,
  garden,
  gym,
  hamaca,
  jacuzzi,
  maletin,
  meditacion,
  parrilla,
  pool,
  restaurante,
  salon,
  secadora,
  spa,
  telescopio,
  terraza,
  planta_baja,
  yoga,
  solarium,
  cowork,
  fitness,
  playground,
  rooftop,
} from "../assets";

export const amenities = [
  { id: 400, icon: garaje, label: "Cocheras" },
  { id: 401, icon: garden, label: "Jardines" },
  { id: 402, icon: terraza, label: "Terraza" },
  { id: 403, icon: gym, label: "Gimnasio" },
  { id: 404, icon: salon, label: "S.U.M" },
  { id: 405, icon: bar, label: "Bar" },
  { id: 406, icon: bell, label: "Conserje" },
  { id: 407, icon: child, label: "Área de Juegos para Niños" },
  { id: 408, icon: cine, label: "Cine" },
  { id: 409, icon: fire, label: "Chimenea" },
  { id: 410, icon: fuego, label: "Fogata" },
  { id: 411, icon: game, label: "Sala de Juegos" },
  { id: 412, icon: hamaca, label: "Hamacas" },
  { id: 413, icon: jacuzzi, label: "Jacuzzi" },
  { id: 414, icon: maletin, label: "Oficinas" },
  { id: 415, icon: meditacion, label: "Sala de Meditación" },
  { id: 416, icon: parrilla, label: "Asador" },
  { id: 417, icon: pool, label: "Piscina" },
  { id: 418, icon: restaurante, label: "Restaurante" },
  { id: 419, icon: secadora, label: "Secadora de Ropa" },
  { id: 420, icon: spa, label: "Spa" },
  { id: 421, icon: telescopio, label: "Observatorio" },
  { id: 422, icon: yoga, label: "Sala de Yoga" },
  { id: 423, icon: elevador, label: "Ascensor" },
  { id: 424, icon: planta_baja, label: "Planta Baja" },
  { id: 425, icon: solarium, label: "Solarium" },
  { id: 426, icon: rooftop, label: "Rooftop" },
  { id: 427, icon: cowork, label: "Coworking" },
  { id: 428, icon: playground, label: "Outdoor Play" },
  { id: 429, icon: fitness, label: "Fitness Park" },
  { id: 430, icon: spa, label: "Espacio de Relajacion" },
];

export const ambients = [
  { id: 500, label: "Monoambiente", available: true },
  { id: 501, label: "1 Dormitorio", available: true },
  { id: 502, label: "2 Dormitorios", available: true },
  { id: 503, label: "3 Dormitorios", available: true },
  { id: 504, label: "4 Dormitorios", available: true },
  { id: 505, label: "5 Dormitorios", available: true },
  { id: 506, label: "Casa", available: true },
  { id: 507, label: "Locales Comerciales", available: true },
  { id: 508, label: "Cochera", available: true },
];
